.loading {
    position: fixed; z-index: 3000;
    top: 50%; left: 50%;
    width: 100px; height: 100px;
    margin-top: -50px; margin-left: -50px;  
    background-color: hsla(0,0,0,.5);
    border-radius: 5px;  
    
    @keyframes lds-spinner {
        0% { opacity: 1; }
        100% {  opacity: 0; }
      }

     &_inner {
        position: relative;
        width: 100%;
        height: 100%;
        
    }
    .ie9 &_inner {
        // background-color: transparent;
        // background-image: url(../images/loading.gif);
        // background-size: contain;
        // background-repeat: no-repeat;
        // background-position: 50% 50%;
        // >i { display: none; }
    }
    &_inner > i {
        position: absolute;
        left: 47%;
        top: 24%;
        animation: lds-spinner linear 1.2s infinite;
        background: #ffffff;
        width: 6%;
        height: 12%;
        border-radius: 40%;
        transform-origin: 50% 216.6666667%;
      }
    &_inner > i:nth-child(1) {
        transform: rotate(0deg);
        animation-delay: -1.1s;
      }
    &_inner > i:nth-child(2) {
        transform: rotate(30deg);
        animation-delay: -1s;
        .ie9 & { opacity: .95;}
      }
    &_inner > i:nth-child(3) {
        transform: rotate(60deg);
        animation-delay: -0.9s;
        .ie9 & { opacity: .9;}
      }
    &_inner > i:nth-child(4) {
        transform: rotate(90deg);
        animation-delay: -0.8s;
        .ie9 & { opacity: .85;}
      }
    &_inner > i:nth-child(5) {
        transform: rotate(120deg);
        animation-delay: -0.7s;
        .ie9 & { opacity: .8;}
      }
    &_inner > i:nth-child(6) {
        transform: rotate(150deg);
        animation-delay: -0.6s;
        .ie9 & { opacity: .7;}
      }
    &_inner > i:nth-child(7) {
        transform: rotate(180deg);
        animation-delay: -0.5s;
        .ie9 & { opacity: .6;}
      }
    &_inner > i:nth-child(8) {
        transform: rotate(210deg);
        animation-delay: -0.4s;
        .ie9 & { opacity: .5;}
      }
    &_inner > i:nth-child(9) {
        transform: rotate(240deg);
        animation-delay: -0.3s;
        .ie9 & { opacity: .4;}
      }
    &_inner > i:nth-child(10) {
        transform: rotate(270deg);
        animation-delay: -0.2s;
        .ie9 & { opacity: .3;}
      }
    &_inner > i:nth-child(11) {
        transform: rotate(300deg);
        animation-delay: -0.1s;
        .ie9 & { opacity: .2;}
      }
    &_inner > i:nth-child(12) {
        transform: rotate(330deg);
        animation-delay: 0s;
        .ie9 & { opacity: .1;}
      }
   

    &-backdrop {
        position: fixed;
        top: 0; right: 0; bottom: 0; left: 0;
        z-index: 3000;
    }


}