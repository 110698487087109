@charset "UTF-8";
@import "./sass/mixins";
@import "../bootstrap/stylesheets/bootstrap";
@import "../components/nav/nav.scss";
@import "../components/loading/loading.scss";


// 动画
@include fadein();

@include fadeinright(33.333333%,fadeinright-sm);
@include fadeinright(15%,fadeinright-xs);

@include fadeinleft();
@include fadeinleft(-33.333333%,fadeinleft-sm);

@keyframes fadein-mod-info {
    0% { opacity: 0; }
    50% { opacity: 0; }
    100% { opacity: 1; }
}

[data-aos] {
    visibility: hidden;
}
[data-aos].animated {
    visibility: visible;
}

[data-aos="mod-anim-left"].aos-animate {
    visibility: visible;
    animation: fadeinleft-sm 1s; 
    >.mod_info,>.btn { animation: fadein-mod-info 1.25s; }
}
[data-aos="mod-anim-righ"].aos-animate {
    visibility: visible;
    animation: fadeinright-sm 1s; 
    >.mod_info,>.btn { animation: fadein-mod-info 1.25s; }
    >.load_con { animation: fadein-mod-info 2s; }
}
.lazyload,.lazyloading {
	opacity: 0;
}
.lazyloaded {
	opacity: 1;
	// transition: opacity .25s;
}
.mod_picture { overflow: hidden; }
.lazyload.mod_bg {
    width: 2560px;
    height: 100%;
}

.enablescroll { height: 100%; overflow: hidden;}


body {
    font-weight: 300;
}

@font-face {
    font-family: 'source';
    src:
      url('../fonts/KaiGenGothicSC-Light.woff2') format('woff2'),
      url('../fonts/KaiGenGothicSC-Light.woff') format('woff'),
      url('../fonts/KaiGenGothicSC-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }


// banner
.mod {   
    position: relative;
    height: 720px;
    overflow: hidden;
    // 背景
    &_picture {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
    &_bg {
        position: absolute;
        top: 0;
        left: 50%;
        height: 100%;
        transform: translatex(-50%);
        background-color: #eee;
    }

    &_img {
        max-width: 100%;
    }
    // 内容
    &_con {
        position: relative;
        height: 100%;
        z-index: 5;
    }
    &_tb {
        display: table;
        position: relative;
        z-index: 6;
        height: 100%;
        width: 100%;
        &-img {
            width: 100%;
            text-align: right;
        }
        &-bg {
            width: 560px;
            color: #fff;
        }
        &-left {
            margin-left: auto; 
            text-align: right;
            // animation: fadeinleft 2s;
        }
    }
    &_td {
        display: table-cell;
        vertical-align: middle;
    }
    
    &_tit {
        line-height: 1.35;
        font-size: 48px;
        letter-spacing: 1px;
        font-family: 'source';
    }
    &_info {
        margin-top: 1.5em;
        text-align: left;
        font-size: $font-size-large;        
        // opacity: .75;
    }
    &_tb-bg &_info,&_tb-bg &_tit {
        text-shadow: 1px 1px 0 hsla(0,0,0,.25);
    }
    &_btn.btn {
        margin-top: 4em;
        width: 9em;
        // text-align: center;
        // color:$brand-primary;
        // border: none;
        // &:focus,&:hover,&:active,&:visited {
        //     color:$brand-primary;
        //     border: none;
        //     outline:none;
        // }
        &:active { outline: none }
    }
    // 单独
    &-index {        
        // background-color: #4a67dd;
        // background-color:rgb(0,42,82);
    }
    &-small {
        height: 640px;
    }
    &-wmdsm {
        height: 336px;
        color: $gray;
        text-align: center;
    }
    &-wmdsm &_info {
        text-align: center;
        font-size: 24px;
    }
    &.mod-load {
        height: 100vh;
    }
}

.box {
    padding-top: 4em;
    padding-bottom: 4em;
    &_tit {
        line-height: 1.35;
        font-family: 'source';
        font-size: 48px;        
        text-align: center;
    }
    &_con {
        display: block;
        width: 100%;
        margin-top: 3em;
    }
    &_img {
        display: block;
        width: 100%;
    }
}

// 免责声明
.disclainer {
    >li {
        position: relative;
        padding-left: 4em;
        margin-top: 2.5em;
        text-align: justify;
        &:first-child { margin-top: 0; }
        >i {
            position: absolute;
            top: 50%; left: 0;
            width: 1em; line-height: 1;
            margin-top: -.5em;
            text-align: right;
            font-size: 40px;
            font-style: italic;
            font-family: 'source',Helvetica,Verdana;
            color: $brand-primary;
        }
    }
}

// 媒体查询
@media screen and (max-width:$screen-lg-min ){
    .mod {   
        height: 580px;
        &-small {
            height: 520px;
        }
        &-wmdsm {
            height: 240px;
        }
    }
    

}
@media screen and (max-width:$screen-md-min ){
    .mod {   
        height: 450px;
        
        &-small {
            height: 380px;
        }
        &-wmdsm {
            height: 170px;
        }
        &_tit { font-size: 36px; }
        &_info {
            margin-top: 1em;
            // font-size: 15px;
        }
        &_tb {
            &-bg { width: 400px; }
        }
    }

    .box {
        &_tit {
            font-size: 36px;        
        }
    }


}
@media screen and (max-width:$screen-sm-min ){
    .mod {   
        height: 450px;
        text-align: center;
        &-small {
            height: 400px;
        }
        &-wmdsm {
            height: 140px;
        }
        &.mod-load {
            height: auto;
        }
        &_tit { font-size: 32px; }
        &_info {
            margin-top: 1em;
            font-size: $font-size-base;
        }
        &_img {
            max-width: 75%;
        }
        &_btn.btn {
            margin-top: 2em;
            width: 9em;
            font-size: 16px;
        }
        &_tb {
            width: 100%;
            &-img {
                margin-top: 1em;
                text-align: center;

            }
            &-bg {
                width: 100%;
                margin-left: auto;
                margin-right: auto;
                text-align: center;
            }
        }
        &-container {
            padding-top: 3em;
            padding-bottom: 3em;
        }
    }
    .mod2 {
        height: auto;
    }

    .box {
        padding-top: 2.5em;
        padding-bottom: 2.5em;
        &_tit {
            font-size: 32px;        
        }
        &_con {
            margin-top: 1.5em;
        }
    }

    // 免责声明
.disclainer {
    >li {
        padding-left: 3em;
        margin-top: 2em;
        padding-right: 1em;
        >i {
            top: 0;
            margin-top: 0;
            font-size: 24px;
        }
    }
}
    

}
@media screen and (max-width:$screen-xs-min ){

}

@import "../components/footer/footer.scss";
@import "../components/level/level.scss";
@import "../components/price/price.scss";
@import "../components/cooperation/cooperation.scss";
@import "../components/aboutus/aboutus.scss";
@import "../components/load/load.scss";
@import "../components/login/login.scss";