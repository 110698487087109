.level {    
    text-align: center;
    &-container {
        padding-top: 150px;
        padding-bottom: 150px;
    }
    &_con {
        height: 420px;
        background-color: #fff;

    }
    &_tit {
        font-size: $font-size-large;
        font-weight: bold;
    }
    &_price {
        margin: 0.75em 0;
        font-size: 36px;
        color: $brand-primary;
    }
    &_info {
        font-size: $font-size-small;
        color: $gray-light;
    }
    &_btn.btn {
        position: absolute;
        bottom: 4em;
        left: 50%;
        width: 10em;
        margin-left: -5em;
        text-align: center;
        // border-radius: 50px;
    }
}
.level-index {    
    .level {
        &_con {
            padding: 3em 1em;
            margin: 0 3%;            
            border-radius: .5em;
            box-shadow: 0 0 1.5em hsla(0,0,0,.1);

        }

    }
}
.level-price { 
    border-left: 1px solid $hr-border;   
    &:first-child { border-left: none;}
    .level {
        &_con {
            padding: 2.5em 1em;
        }
        &_btn.btn {
            bottom: 3em;
        }
    }
}


// 媒体查询
@media screen and (max-width:$screen-lg-min - 1){
    .level {            
        &-container {
            padding-top: 110px;
            padding-bottom: 110px;
        } 
        &_con {
            height: 360px;  
        }   
    }


}
@media screen and (max-width:$screen-md-min - 1){   
    .level {     
        &-container {
            padding-top: 80px;
            padding-bottom: 80px;
        }
        &_con {
            height: 290px; 
        } 
        &_tit {
            font-size: $font-size-base;
        }
        &_price {
            margin: 0.75em 0;
        }
        &_info {
            font-size: $font-size-small;
        }
        &_btn.btn {
            bottom: 2em;
            width: 10em;
            margin-left: -5em;
        }
    }
    .level-index {  
        .level {
            &_con {
                margin: 0;
                padding-top: 2em;
                padding-bottom: 2em;
            }    
        }  
    }
    .level-price { 
        .level {
            &_con {
                padding-top: 2em;
                padding-bottom: 2em;
            }
            &_btn.btn {
                bottom: 2em;
            }
        }
    }

}
@media screen and (max-width:$screen-sm-min - 1){   
    .level {
        &-container {
            padding-top: 1em;
            padding-bottom: 2em;
        }  
        &_con {
            height: auto;
        }       
        &_tit {
            font-size: $font-size-base;
        }
        &_price {
            margin: 0.5em 0;
            font-size: 32px;
        }
        &_info {
            font-size: $font-size-xsmall;
        }
        &_btn.btn {
            position: static;
            display: block;
            width: 10em;
            margin: 2em auto 0 auto;
        }
    }    
    .level-index {  
        .level {
            &_con {                
                padding-top: 2em;
                padding-bottom: 2em;
                margin-top: 1em;  
            }    
        }  
    }
    .level-price {       
        border-left: none;
        margin-top: 2em;
        background-color: #fff;
        box-shadow: 0 0 1.5em transparentize($brand-primary,.75);
        &:first-child { margin-top: 0; }
     
    } 
}
