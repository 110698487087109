.load {
    position: absolute; z-index: 5;
    top: 0; right: 0; bottom: 0; left: 0;
    color: #ffffff;
    background: linear-gradient(175deg,#4C6DE3, #4556CF);
    .ie9 & {
        background: #4C6DE3 url(../images/mod-load-bg.png) 50% 50% no-repeat;
    }
    &_bg {
        position: absolute;z-index: 1;
        top: 0; right: 0; bottom: 0; left: 0;
        overflow: hidden;
        .ie9 & { display: none; }
        >i {
            position: absolute;
            background-color: #000;
            opacity: .5;
            border-radius: 50%;
            &:nth-child(1) {
                left: -21.875vw; bottom: -35.4166vw;
                width: 61.87vw; height: 61.87vw;
                background: linear-gradient(225deg, hsla(233, 59%, 54%, .5), hsla(227, 63%, 57%,0))
            }
            &:nth-child(2) {
                width: 28.0208vw; height: 28.0208vw;
                right: -5.2083vw; bottom: -8.3333vw;
                background: linear-gradient(-35deg, hsla(233, 59%, 54%,0), #4d6cdb)
            }
            &:nth-child(3) {
                width: 19.9479vw; height: 19.9479vw;
                right: 10px; top: -9.375vw;
                background: linear-gradient(180deg,hsla(233, 59%, 54%,.0),hsla(227, 66%, 68%,.25) )
            }
            &:nth-child(4) {
                width: 18.6458vw; height: 18.6458vw;
                left: 25%; top: -5%;
                background: linear-gradient(180deg, hsla(233, 59%, 54%,.39),hsla(227, 100%, 66%,.6))
            }
            &:nth-child(5) {
                width: 248px; height: 248px;
                left: 50%;
                top: 50%;
                margin-left: 350px;
                margin-top: -150px;
                background: linear-gradient(180deg, hsl(233, 59%, 54%), #4C6AD6)
            }
            &:nth-child(6) {
                width: 132px; height: 132px;
                left: 50%;  top: 50%;
                margin-left: -50px;
                background: linear-gradient(-25deg,  hsla(233, 59%, 54%,.8), hsla(227, 77%, 61%,.8));
            }
        }

    }
    &_con {
        margin-top: 8em;
        &_pcbtn {
            display: inline-block;
            vertical-align: middle;
            >.btn {
                display: block;
                padding-right: 1.5em;
                padding-left: 1.5em;
                color: $brand-primary;
                border: none;
                &:hover,&:visited,&:focus {
                    color: $brand-primary;
                }
                &:first-child { margin-bottom: 1.5em;}
            }
        }
        &_qr {
            display: inline-block;
            vertical-align: middle;
            width: 120px; height: 120px;
            margin-left: 2em; 
            >img {
                display: block;
                width: 100%;
                height: 100%;
            }
        }
        &_mbbtn.btn {
            display: none;
            &:hover,&:visited,&:focus {
                color: $brand-primary;
            }
        }
    }

    .ft {
        position: absolute; z-index: 6;
        bottom: 0; left: 0; right: 0;
    }

}


@media screen and (max-width:$screen-sm-min - 1){   
    .load {
        &_row {
            display: flex;
            flex-direction: column;
            height: 100vh;
        }

        &_item1 {
            order: 2;            
            >.mod {
                height: 35vh; 
                padding-bottom: 2em;
                .mod_td { vertical-align: top;}
            }
        }
        &_item2 {  
            order: 1; 
            >.mod {
                height: 65vh; 
            }          
        }
        &_con {
            margin-top: 2em;
            &_pcbtn,&_qr { display: none;            }
            &_mbbtn.btn {
                display: block;
                margin-left: auto;
                margin-right: auto;
                padding-left: 3em;
                padding-right: 3em;
                color: $brand-primary;
                border: none;
            }
        }
    
        .ft {
            position: static;
        }
       
    }
    
}
