// 底部
.ft {
    color: #fff;
    background-color: #323a4a;
    &_con {
        padding: 3em 0;
    }
    // 导航
    &_nav {
        &_tit { font-size: $font-size-base }
        &_list {
            line-height: 2;
            margin-top: 1em;
            font-size: $font-size-xsmall;
            >li {
                >a {
                    color: #757f91;
                }
            }
        }
    }
    // 联系方式
    &_contact {
        padding-top: 2.5em;
        text-align: center;
        &_tel {
            line-height: 1.1;
            font-size: 24px;
        }
        &_info {
            margin-top: .25em;
            font-size: $font-size-xsmall;
            color: #757f91;
        }
        &_btn.btn  {
            display: block;
            width: 8em;
            margin: 2.5em auto 0 auto;
            color: currentColor;
            border-color: currentColor;
            background: none;
            // border-radius: 50px;
            &:hover,&:focus {
                color: currentColor;
                border-color: currentColor;
                background: hsla(0,0,100%,.1);
                outline: none;
            }
            &:active{
                background: hsla(0,0,100%,.05);
            }
        }
    }
    // 二维码
    &_qr {
        width: 120px;
        margin-left: auto;
        text-align: center;
        &_img {            
            height: 120px;
            height: 120px;
            >img {
                display: block;
                width: 100%;
                height: 100%;
            }
        }
        &_info {
            margin-top: 1em;
            font-size: $font-size-xsmall;
            opacity: .35;
        }
    }
    // 版权
    &_copyright {
        height: 3.5em;
        line-height: 3.5;
        font-size: $font-size-xsmall;
        color: #757f91;
        background-color: hsla(0,0,0,.15);
        &_l { float: left; }
        &_r { float: right; }

    }

}



// 媒体查询
@media screen and (max-width:$screen-lg-min - 1){}
@media screen and (max-width:$screen-md-min - 1){
    .ft {
        text-align: center;
        &_con { padding: 3em 0; }
        // 二维码
        &_qr {
            margin-right: auto;
            padding-top: 3em;
        }    
    }  
}

@media screen and (max-width:$screen-sm-min - 1){
    .ft {        
        &_con {
           padding-top: 2em;
           padding-bottom: 2em;
        }
        // 联系方式
        &_contact {
            margin-top: 2em;
            padding-top: 2em;
            padding-bottom: 2em;
            border-top: 1px solid hsla(0,0%,100%,0.05);
            border-bottom: 1px solid hsla(0,0%,100%,0.05);
        }
        // 二维码
        &_qr {  padding-top: 2em; }
        // 版权
        &_copyright { font-size: 9px; }
    
    }
    

}
