.aboutus {
    &_tit {
        line-height: 1.2;
        font-size: 36px;
        text-align: center;
    }
    // 发展历程
    &_fzlc {
        padding-top: 6em;
        padding-bottom: 6em;
        &_con {
            position: relative;
            padding-top: 1px;
        }
        &_pic {
            position: absolute;
            width: 100%;
        }
        &_img {
            width: 50%;
        }
        &_box {
            position: relative;
            z-index: 5;
            width: 66%;
            margin-left: auto;
            margin-top: 6em;
            padding: 2.5em;
            font-size: $font-size-small;
            background-color: #fff;
            box-shadow: 0 0 1.5em transparentize($brand-primary,.75);

        }
        &_list {
            margin-left: 4em;          
            border-left: 1px solid #eee;
            >li {
                position: relative;
                margin-top: 1em;
                &:first-child { margin-top: 0; }
            }
        }
        &_list >li:first-child >&_time:after {
            background-color: $brand-primary;
            width: 8px;
            height: 8px;
            margin-top: -4px;
            margin-left: -4px;
            box-shadow: 0 0 0 4px transparentize($brand-primary,.75);
        }
        &_time {
            position: absolute;
            right: 100%;
            width: 4em;
            &:after {
                content: '';
                position: absolute;
                top: 50%;
                left: 100%;
                width: 6px;
                height: 6px;
                margin-top: -3px;
                margin-left: -3px;
                border-radius: 50%;
                background-color: #aaa;
            }
        }
        &_info {
            padding-left: 1em;  
        }
    }
    // 我们的价值观
    &_jzg {
        padding-top: 6em;
        padding-bottom: 6em;
        &_box {
            margin-top: 3em;
            padding: 1px 2em 2em 2em;
            background-color: #fff;
            text-align: justify;
            box-shadow: 0 0 1.5em transparentize($brand-primary,.75);
        }
        &_svg {
            display: block;
            width: 4em;
            margin: 3em auto;
            fill: $brand-primary;
        }
        &_tit {
            font-weight: bold;
            font-size: 20px;
            text-align: center;
        }
        &_info {
            height: 8em;
            margin-top: 1em;
        }
    }

    // 我们前瞻人
    &_qzr {
        padding-bottom: 6em;
        &_pic {
            display: block;
            width: 100%;
        }
        &_img {
            display: block;
            width: 100%;
            margin-top: 3em;
        }
    }
    // 品牌影响力
    &_ppyxl {
        background-color: #fafafa;
        &_tit {
            margin-bottom: 1em;
        }
    }
    // 联系方式
    &_contact {
        &_row { }
        &_item {
            margin-top: 3em;
            &:nth-child(1){ text-align: left;}
            &:nth-child(2){
                // text-align: right;
                >div {
                    &:first-child { margin-bottom: 2em; }
                    >span {
                        display: block;
                    }
                }
            }
            >div {
                &:first-child { margin-top: 0; }
                margin-top: 0.5em;
            }
        }
    }
    

}


// 媒体查询
@media screen and (max-width:$screen-lg-min - 1){ }

@media screen and (max-width:$screen-md-min - 1){
    .aboutus {
        // 发展历程
        &_fzlc {
            &_con {
                padding-top: 0;
                box-shadow: 0 0 1.5em transparentize($brand-primary,.75);
            }
            &_pic { position: static; }
            &_img { width: 100%; }
            &_box {
                width: 100%;
                margin-left: auto;
                margin-right: auto;
                margin-top: -15em;
                padding: 2.5em;
                box-shadow: none;                
            }     
            &_lis {
                margin-left: 6em;
            }
            &_time {
                font-weight: bold;
                font-size: $font-size-large;
            }
            &_info {
                font-size: $font-size-base;
            }      
        }
        // 我们的价值观
    &_jzg {
        &_svg {
            width: 3em;
            margin: 2.5em auto;
        }   
    }


    }
}

@media screen and (max-width:$screen-sm-min - 1){
    .aboutus {
        &_tit { font-size: 32px;}
        // 发展历程
        &_fzlc {            
            padding-top: 3em;
            padding-bottom: 3em;
            &_con {
                margin-top: 2em;
                box-shadow: none;
            }
            &_pic { position: static; }
            &_img { width: 100%; }
            &_box {
                width: 100%;
                margin-top: -6em;
                padding: 1.5em 0 0 0;
            } 
            &_list {
                margin-left: 0;   
                padding-left: 1em;
            }
            &_list >li:first-child >&_time:after {
                margin-left: 0;
            }
            &_time {
                position: relative;
                right: auto;
                width: auto;
                &:after {
                    position: absolute;
                    left: -1em;
                    margin-left: 0;
                }
            }
            &_info {
                padding-left: 0;
            }     
        }
        // 我们的价值观
        &_jzg {
            padding-top: 3em;
            padding-bottom: 3em;
            &_box {
                margin-top: 1em;
                padding: 1.5em 1em 1.5em 5em;
            }
            &_svg {
                position: absolute;
                top: 50%;
                left: 2em;
                width: 2.5em;
                height: 2.5em;
                margin-top: -1.25em;
                margin: 0;
            }
            &_tit {
                text-align: left;
                font-size: $font-size-large;
            }   
            &_info {
                height: auto;
                margin-top: 0.75em;
            } 
        }
    

        // 联系方式
        &_contact {
            &_item {
                margin-top: 1.5em;
                &:nth-child(2){
                    text-align: left;
                    >div {
                        &:first-child { margin-bottom: 0em; }
                        >span {
                            display: inline;
                        }
                    }
                }
            }
        }
    }
   

}

