// 价格
.price {
    position: relative;
    z-index: 6;
    margin-top: -500px;
    margin-bottom: 4em;
    padding-top: 4em;
    padding-bottom: 4em;
    background-color: #fff;
    box-shadow: 0 0 1.5em transparentize($brand-primary,.75);
    &_box {
        padding-left:  8%;
        padding-right:  8%;
    }
    &_declare{
        text-align: justify;
        >h3 {
            margin-top: 2em;
            font-size: $font-size-large;
            font-weight: bold;
        }
        >dl {
            padding-left: 2.25em;
            >dt {
                margin-top: 1em;                
            }
            >dd {
                // padding-left: 1.5em;
                margin-top: .5em;
                font-size: $font-size-small;
                color: $gray-light;
                
            }
        }
    }

    // 基础服务
    &_table {
        table-layout: fixed;           
        border: 1px solid $table-border-color;
        border-top: 3px solid $brand-primary;
        >thead {
            >tr {
                background-color: $table-bg-accent;
                >th {                    
                    vertical-align: middle;
                    border-bottom: none;
                }
            }
        }
        >tbody {
            >tr {
                >td {
                    white-space: normal;
                    vertical-align: middle;
                } 
                >td[rowspan] {
                    
                    border-right: 1px solid $table-border-color;
                }   
                >td:nth-last-child(3){
                    padding-left: 2em;
                }
            }
        }        
        &_correct {
            fill: $brand-primary;
        }
        &_error {            
            fill: $brand-danger;
        }
        &_tit {
            padding: 0;
            font-weight: bold;
            font-size: $font-size-large;
            text-align: center;
        }
        svg {
            display: inline-block;
            vertical-align: middle;
            width: 1.25em;
            margin-right: 1em;
        }
        span {
            display: inline-block;
            vertical-align: middle;            
            font-size: $font-size-small;
            color: $gray-light;
        }
        .btn-link {
            padding-left: 0;
            padding-right: 0;
            &:focus { outline: none }
        }
    }
    // 基础服务
    &_jcfw {
        >thead {
            >tr {
                >th {
                    height: 4em;
                    padding-left: 1em;
                    padding-right: 1em;
                    &:nth-child(1){ width: 20%; }
                    &:nth-child(2){ width: 28%;   }
                    &:nth-child(3){ width: 26%; }
                    &:nth-child(4){ width: 26%; } 
                }
            }
        }
        >tbody {
            >tr {
                >td {
                    height: 4em;
                    padding-left: 1em;
                    padding-right: 1em;
                } 
            }
        }
    }

    // 增值服务
    &_zzfw {
        .price_table_tit {
            padding-left: 2em;
            text-align: left;
        }
        >thead {
            >tr {
                >th {
                    padding-top: 1.5em;
                    padding-bottom: 1.5em;
                    &:nth-child(1){ width: 30%; }
                    &:nth-child(2){ width: 70%;   }
                }
            }
        }
        >tbody {
            >tr {
                >td {
                    padding-top: 1.5em;
                    padding-bottom: 1.5em;
                } 
            }
        }
        &_row {
            >div {
                padding-left: 0;
                padding-right: 0;
            }
            &+span {
                margin-top: .75em;
                display: block;
            }
        }

    }
}


// 媒体查询
@media screen and (max-width:$screen-lg-min - 1){
    .price {
        margin-top: -400px;
        &_box {
            padding-left: 6%;
            padding-right: 6%;
        }
    }

}
@media screen and (max-width:$screen-md-min - 1){
    .price {
        margin-top: -350px;
        margin-bottom: 3em;
        padding-top: 3em;
        padding-bottom: 3em;
        &_box {
            padding-left: 4%;
            padding-right: 4%;
            &_tit {     
                margin-bottom: .75em;      
            }
        }
        
        // 表格
        &_table {
            >tbody {
                >tr {                  
                    >td[rowspan] {}
                }
            }  
            &_tit {
                font-size: $font-size-base;
            }     
           
 
        }
    }
   

}
@media screen and (max-width:$screen-sm-min - 1){
    .table-responsive > .price_table > tbody > tr > td {
        white-space: normal;
    }
    .table-responsive > .price_jcfw > tbody > tr > td.price_table_tit {
        white-space: nowrap;
    }
    .price {
        margin-top: -400px;
        margin-bottom: 2em;
        padding-top: 2em;
        padding-bottom: 2em;
        background: none;
        box-shadow: none;
        &_con {
            margin-left: $grid-gutter-width / 2;
            margin-right: $grid-gutter-width / 2;
            
        }
        &_box {
            padding-left: 0;
            padding-right: 0;
            &_tit {     
                font-size: 32px;
                margin-bottom: 0.5em;
            }
        }
        &_declare{
            >h3 {
                margin-top: 2em;
                font-size: $font-size-base;
            }
            >dl {
                padding-left: 0;
            }
        }
        
        // 表格
        &_table {
            border-left: none;
            border-right: none;
            border-bottom: none;
            font-size: $font-size-small;
            >tbody {
                >tr {
                    >td {
                        padding-left: .75em;
                        padding-right: .75em;
                    } 
                }
            }  
            &_tit { font-size: $font-size-small; }       
            svg {
                width: 1em;
                margin-right: .5em;
            }
            span {
                font-size: $font-size-xsmall;;
            }
            .btn-link { font-size: $font-size-small; }
 
        }
        
         // 基础服务
        &_jcfw {
            max-width: 767px;
            min-width: 500px;
            >thead {
                >tr {
                    >th {                        
                        &:nth-child(1){ width: 2.5em; }
                        &:nth-child(2){ width: 6em;   }
                        &:nth-child(3){ width: 6em; }
                        &:nth-child(4){ width: 10em; }                     
                    }
                }
            }
            >tbody {
                >tr { 
                    >td:nth-last-child(3){ padding-left: 0.75em; }  
                }
            }
            .price_table_tit {
                text-align: center;
                >em {
                    display: block;
                    margin-left: auto;
                    margin-right: auto;
                    writing-mode: vertical-rl;
                }
                
            }
        }
        //增值服务
        &_zzfw {
            max-width: 767px;
            min-width: 500px;
            .price_table_tit {
                padding-left: .75em;
            }
            >thead {
                >tr {
                    >th {
                        padding-top: 1em;
                        padding-bottom: 1em;
                        &:nth-child(1){ width: 20%; }
                        &:nth-child(2){ width: 80%;   }
                    }
                }
            }
            >tbody {
                >tr {
                    >td {
                        padding-top: 1em;
                        padding-bottom: 1em;
                    } 
                }
            }
            &_row + span { margin-top: .5em;}
        }
    }
   
}