.coop {
    &_tit {
        line-height: 1.2;
        font-size: $font-size-h2;
        text-align: center;
    }
    &_jion {        
        margin-top: 6em;
        margin-bottom: 6em;
        &_item {
            margin-top: 2em;
            position: relative;
            box-shadow: 0 0 1.5em transparentize($brand-primary,.75);
        }
        &_con { 
            float: left;
            padding: 2em;
            text-align: justify;
        }   
        &_pic { float: right;  }
        &_img {
            display: block;
            width: 100%;
        }        
        &_tit {
            margin-top: 1em;
            line-height: 1.2;
            font-size: 20px;
            font-weight: bold;
        }
        &_info {
            margin-top: 1.5em;
            // font-size: $font-size-small;
        }
    }
    &_cpzy,&_ppzy {           
        &_con { width: 53.3333%;}
        &_pic { width: 46.6667%;}
    }
    &_ptzy {           
        &_con {
            float: right;
            width: 46.6667%;
        }
        &_pic {
            float: left;
            width: 53.3333%;
        }
    }

    // 代理
    &_agency{
        padding: 6em 1.5em;
        background-color: #fafafa;
        &_row {
            margin-top: 3em;
        }
        &_item {
            float: left;
            width: 20%;
            padding-left: 1.2%;
            padding-right: 1.2%;
        }
        &_box {
            padding: 0 10% 2em 10%;
            border: 1px solid $hr-border;
            border-top: 2px solid $brand-primary;
            background-color: #fff;
            text-align: justify;
        }
        &_svg {
            display: block;
            width: 4em;
            margin: 3em auto;
            fill: $brand-primary;
        }
        &_tit {
            font-weight: bold;
            font-size: 20px;
            text-align: center;
        }
        &_info {
            height: 12em;
            margin-top: 1em;
        }
    }
    
    
    
    
    
    
}



// 媒体查询
@media screen and (max-width:$screen-lg-min - 1){
    .coop {        
        &_ptzy {   
            &_con {
                width: 50%;
            }      
            &_pic {
                position: absolute;
                top: 50%;
                left: 0;
                width: 50%;
                transform: translateY(-50%);
            }
        }
         // 代理
        &_agency{
            &_row {
                margin-top: 0;
            }
            &_item {
                width: 33.333333%;
                margin-top: 2em;
            }
            &_svg {
                width: 3.5em;
                margin: 2em auto;
            }
            &_info {
                height: 8em;
            }
        }
   
    }

}
@media screen and (max-width:$screen-md-min - 1){
    .coop {
        &_jion { 
            &_tit { margin-top: 0; }
        }

        &_cpzy,&_ppzy {           
            &_pic {
                position: absolute;
                right: 0;                
            }
        }
        &_ppzy {
            &_pic {
                bottom: 0;                
            }
        }
        &_ptzy {  
            &_con {  width: 51%; } 
            &_pic {
                position: absolute;
                top: 50%;
                left: 0;
                width: 49%;
                transform: translateY(-50%);
            }
        }
         // 代理
         &_agency{
            padding: 4em 1em;
            &_item {
                width: 50%;
                // margin-top: 2em;
            }
            &_svg {
                width: 3em;
                margin: 1.5em auto;
            }
            &_tit {
                font-size: 18px;
            }
            &_info {
                height: 7em;
                // font-size: $font-size-small;
            }
        }
    }

}
@media screen and (max-width:$screen-sm-min - 1){
    .coop {
        &_tit { font-size: 30px;  }
        &_jion {        
            margin-top: 3em;
            margin-bottom: 3em;
            &_item {
                margin-top: 2em;
                overflow: hidden;
                
            }
            &_con {
                position: relative; 
                padding: 1.5em;
                background-color: #fff;                
            }             
            &_tit {
                position: absolute;
                top: -2.5em;
                color: #fff;
                font-size: 22px;
            }
            &_tit + &_info { margin-top: 0; }
            &_info {
                margin-top: 1em;
                // font-size: $font-size-small;
            }
            &_pic {
                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    background: linear-gradient(90deg,hsla(0,0,0,.6),transparent);
                }  
            }
        }
        &_cpzy,&_ppzy,&_ptzy {
            &_con {
                float: none;
                width: 100%;
            }
            &_pic {
                position: static;
                float: none; 
                width: 100%;                             
            }
        }
        &_cpzy {           
            &_con {
                margin-top: -60px;
            }
        }
        &_ppzy {           
            &_con {
                margin-top: -50px;
            }
        }
        &_ptzy {           
            &_con {
                margin-top: -20px;
            }
        }
         // 代理
         &_agency{
            padding: 3em .75em;
            &_item {
                width: 100%;
                margin-top: 1.5em;
                
            }
            &_box {
                position: relative;
                padding: 1.5em 1.5em 1.5em 6em;
                // border-top: 1px solid $hr-border;
                border: none;
                // border-left: 2px solid $brand-primary;
                box-shadow: 0 0 .75em transparentize($brand-primary,.8);
            }
            &_svg {
                position: absolute;
                top: 50%;
                left: 1.5em;;
                margin-top: -1.5em;
                // float: left;
                width: 3em;
                // margin: 1.5em auto;
            }
            &_tit {
                font-size: 18px;
                text-align: left;
            }
            &_info {
                height: auto;
                margin-top: 0.75em;
            }
        }
    }

}

