// 登录注册
.lgreg {
    // 头部
    &_hd {
        padding: 0;
        >.close {
            position: absolute;
            top: 0; right: 0;
            width: 1.5em; height: 1.5em; line-height: 1.5;
            text-align: center;
        }

    }
    // tab
    &_tabnav {
        height: 3em;
        line-height: 3em;  
        margin: 0;
        text-align: center;
        font-size: $font-size-large;
        &_item {
            float: left;
            width: 50%;
            >a {
                position: relative;
                display: block;   
                color: $gray-light;  
                &:hover {
                    color: $brand-primary;
                }           
            }
            &.active >a{
                color: $brand-primary;
                &:before {
                    content: '';
                    position: absolute;
                    left: 0; right: 0; top: 100%;
                    border-bottom: 1px solid;
                }
            }
        }
        &_forgetpwd {
            display: none;
            position: relative;
            clear: both;
            >a {
                
            }
        }        
    }
    &_return {
        position: absolute;
        top: 0; left: 1em;
        color: $gray-light;
        font-size: $font-size-base;
        cursor: pointer;
        >svg {
            display: inline-block;
            vertical-align: middle;
            height: 1em;
            width: .579125em;
            margin-right: .25em;
            fill: currentColor;
        }
        >span {
            display: inline-block;
            vertical-align: middle;
        }
        &:hover,&:focus {
            color: $brand-primary;
        }
        &:active,&:active:hover {
            color: darken($brand-primary,15%);
        }

    }

    &_bd {
        padding-top: 0;
        padding-bottom: 2em;
        overflow: hidden;
    }
    &_tabpane {
        animation: fadeinright-xs .5s;
        &_active {
            animation: fadeoutleft .5s;
        }      
    }
    // 表单
    &_con {
        padding-left: 2em;
        padding-right: 3em;
    }
    &_item {
        position: relative;
        margin-left: 5.5em;
        margin-top: 2.5em;
    }
    &_item.code >&_txt { padding-right: 8em;}
    &_item.password >&_txt { padding-right: 5em;}
    &_lb {
        position: absolute;
        top: 50%;  right: 100%;
        width: 5.5em; height: 2em; line-height: 2;
        margin-top: -1em; padding-right: 0.5em;
        text-align: right;
        white-space: nowrap;
    }
    &_txt {
        display: block;
        width: 100%; height: 2em; line-height: 1.5;
        padding-top: 0.25em;
        padding-bottom: 0.25em;
        outline: none;
        border: none;
        border-bottom: 1px solid #ddd;
        &:focus {
            outline: none;
            border-bottom: 1px solid $brand-primary;
            transition: all .25s;
        }
        &.disabled {
            background: none;
            border-bottom: none;
        }
    }
    &_btn {
    }
    &_codeget {
        position: absolute;
        right: 0; top: 0;
        height: 2em; line-height: 2;
        padding: 0;
    }
    &_codeimg {
        position: absolute;
        right: 0; top: 0;
        height: 2em; width: 6em;
        >img {
            display: block;
            width: 100%; height: 100%;
        }
    }
    &_msg {
        display: none;
        position: absolute;
        top: 100%; left: 0;
        margin-top: 0.5em;
        color: $brand-danger;
        font-size: $font-size-xsmall;

    }
    &_mzsm {
        margin-top: 1em;
        font-size: $font-size-xsmall;
        color: $gray-light;
        >a {white-space: nowrap;}
    }
    &_forgetpwd {       
        position: absolute;
        top: 50%; right: 0;
        height: 2em; line-height: 2;
        padding: 0;
        margin-top: -1em;
        color: $gray-light;
    }
    // 修改密码成功
    &_suc {
        padding-left: 3em;
        padding-right: 3em;
        text-align: center;
        &_svg {
            // display: inline-block;
            // vertical-align: middle;
            width: 4em;
            margin-top: 2em;
            fill: $brand-primary;
        }
        &_info {
            margin-top: 2em;
            // display: inline-block;
            // vertical-align: middle;
        }
        >.btn {
            margin: 3em auto 0;
            width: 12em;
        }
    }

    // 状态
    // 显示错误信息
    &_item.show-msg &_txt {
        border-bottom-color: $brand-danger;
    }
    &_item.show-msg &_msg {
        display: block;
    }
    // 显示找回密码
    &_tabnav.show-forgetpwd >&_tabnav{
        &_item { display: none;}
        &_forgetpwd { display: block; }
    }

}
// 媒体查询
@media screen and (max-width:$screen-lg-min - 1){}
@media screen and (max-width:$screen-md-min - 1){}
@media screen and (max-width:$screen-sm-min - 1){
    .lgreg {    
        // tab
        &_tabnav {
            >li {
                >a {
                    height: 3em;
                    line-height: 3;
                }
            }
        }

        &_bd {
            padding-bottom: 2em;
        }
        // 表单
        &_con {
            padding-left: .25em;
            padding-right: .25em;
            >li:last-child {
                margin-left: 0;
            }
        }
        &_item {
            margin-top: 2em;
            margin-left: 4em;
        }
        &_lb {
            width: 4em;
            padding-right: 0;
            text-align: left;
            white-space: nowrap;
        }
        &_txt {
            padding-left: 0;
            padding-right: 0;
            border-radius: 0;
        }
        &_item.code >&_txt { padding-right: 8em;}
        &_item.password >&_txt { padding-right: 5em;}
        &_msg { font-size: 10px; }
        &_mzsm {  font-size: 10px; }


    }

      

}
@media screen and (max-width:$screen-xs-min - 1){}