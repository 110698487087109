// 导航
.hdnav {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
    height: 5em;
    &-normal {
        position: static;
        background-color: $brand-primary;
    }
    &_log {
        float: left;
        margin-top: 1.5em;
    }
    &_svg {
        display: block;
        height: 2.65em;
        line-height: 2.65em;
        width: 13.26656em;
        fill: #fff;       
    }
    &_list {
        float: right;
        line-height: 5em;
        >li {
            float: left;
        }
    }
    &_link {
        position: relative;
        display: block;
        padding: 0 20px;
        color: #fff;
        &:before {
            content: '';
            display: none;
            position: absolute;
            left: 20px;
            right: 20px;
            bottom: 20px;
            height: 2px;
            background-color: currentColor;
        }
        &:hover,&.current {
            color: #fff;
            &:before {
                display: block; 
            }            
        }
        &:focus { color: #fff; }
    }
    &_login {
        height: 2.5em;
        line-height: 2.5;
        margin-top: 1.25em;
        margin-left: 1em;
        color: #fff;
        border: 1px solid;
        border-radius: 2.5em;
        overflow: hidden;
        >span {
            position: relative;
            float: left;
            width: 50%;
            cursor: pointer;
            &:hover {
                background-color: hsla(0,0,100%,.1);
            }
            &:first-child {
                padding-left: 1em;
                padding-right: 0.5em;
                border-radius: 2.5em 0 0 2.5em;
            }
            &:nth-child(2) {
                padding-left: .5em;
                padding-right: 1em;
                border-radius: 0 2.5em 2.5em 0;
                &::before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 0;
                    height: 1em;
                    margin-top: -0.5em;
                    border-left: 1px solid;
                    opacity: .25;
                }
            }
        }
    }
    &_menu { display: none;}
}



// 媒体查询
@media screen and (max-width:$screen-lg-min - 1){

}
@media screen and (max-width:$screen-md-min - 1){
    .hdnav { 
        height: 3.5em;
        line-height: 3.5em;
        &_log {
            margin-top: .75em;
            line-height: 2.5em;
        }
        &_svg {
            height: 2em;
            width: 10.0125em;
        }

        // &_link {
        //     padding: 0 12px;
        // }
        // &_login {
        //     margin-left: 1em;
        //     >span {
        //         &:first-child { padding-left: .75em;}
        //         &:nth-child(2) {  padding-right: .75em; }

        //     }
        // }

        &_list {
            display: none;            
        }
        &_link {
            height: 3.5em;
            line-height: 3.5em;
            padding-left: 1em;
            padding-right: 1em;
        }
        &_login {
            height: auto;
            margin-top: 0;
            margin-left: 0;
            color: $gray-dark;
            border: none;
            >span {
                display: block;
                float: none;
                width: 100%;
                height: 3em;
                line-height: 3;
                &:hover { background-color: none; }
                &:first-child {
                    padding-left: 1em;
                    padding-right: 1em;
                }
                &:nth-child(2) {
                    padding-left: 1em;
                    padding-right: 1em;
                    border-top: 1px solid $hr-border;
                    &::before { display: none;  }
                }
            }
        }
        &_menu {
            display: block;
            position: absolute;            
            right: 0;
            height: 3.5em;
            width: 3.5em;
            color: #fff;
            &_ico {
                position: absolute;
                left: 50%; top: 50%;
                height: 2px; width: 2em;
                margin-top: -1px; margin-left: -1em;
                background-color: currentColor;
                &:nth-child(1){
                    transform: translateY(-.6em);
                }
                &:nth-child(2){}
                &:nth-child(3){
                    transform: translateY(.6em);
                }
            }
        }
        &_mask {
            display: none;
            position: fixed;
            top: 0; right: 0; bottom: 0; left: 0;
            z-index: $zindex-modal-background + 1;
        }
    }
     // 导航状态
     .show-navlist .hdnav {        
        &_list {
            display: block;
            position: fixed; z-index: $zindex-modal;
            top: 0; right: 0; bottom: 0;
            width: 75%;
            padding-top: 3.5em;
            background-color: #fff;
            overflow-y: auto;
            animation: fadeinright-sm .25s;            
            >li {
                float: none;
                border-top: 1px solid $hr-border;
            }
        }
        &_link {
            color:$gray-dark;
            
            &.current { color: $brand-primary; }
            &:before { display: none;}

        }
        &_menu {
            position: fixed;
            z-index: $zindex-modal;
            color: $gray-light;
            &_ico {
                transform-origin: 50% 50%;
                transition: all .25s;
                &:nth-child(1){ transform: rotate(-45deg); }
                &:nth-child(2){ display: none; }
                &:nth-child(3){ transform: rotate(45deg); }
            }
        }
        &_mask {
            display: block;
        }
    }
    

}
@media screen and (max-width:$screen-sm-min - 1){
    .hdnav {
        text-align: left;
    }
      
   
  

}
@media screen and (max-width:$screen-xs-min - 1){

}